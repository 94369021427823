<template>
  <!-- <navigator/> -->
  <div></div>
  <!-- <Login/> -->
  <router-view></router-view>
  <!-- <Home/> -->
</template>

<script>
import { ref } from 'vue';
// import Login from './components/login.vue'

// import Home from './components/home.vue'
// import Home from './components/home.vue'
export default {
  name: 'App',
  // components:{ Login,},
  setup() {
    let success = ref(false);
    function login(suc) {
      success = suc;
      console.log(success);
    }
    return {
      login,
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  white-space: pre-wrap;
}
</style>
