<template>
  <a-layout style="min-height: 100vh">
    <a-layout-sider v-model:collapsed="collapsed" collapsible>
      <div class="logo">
        <svg
          t="1660981069456"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="10641"
          width="32"
          height="32"
        >
          <path
            d="M776.8 959H247.5c-8.4 0-9.7-27-9.7-27 10.5-9.1 61.7-28.9 77.5-28.9h394.1c21.7 0 64.5 15.4 77.2 29-0.1 0-1.9 26.9-9.8 26.9z"
            fill="#3173E7"
            p-id="10642"
          ></path>
          <path
            d="M776.8 939.7H247.5c-8.4 0-12.8-7.7-7.2-12.5l33.8-29.2c10.5-9.1 25.5-14.2 41.2-14.2h394.1c21.7 0 42 8.1 54.6 21.7l20.7 22.3c4.6 5 0 11.9-7.9 11.9z"
            fill="#E7F5FD"
            p-id="10643"
          ></path>
          <path
            d="M784.7 927.9L764 905.5c-12.6-13.6-33-21.7-54.6-21.7h-27c21.7 0 42 8.1 54.6 21.7l20.7 22.3c4.6 5 0 11.9-7.9 11.9h27c7.9 0 12.5-6.9 7.9-11.8z"
            fill="#F0F8FC"
            p-id="10644"
          ></path>
          <path
            d="M512.1 877.7c-55.7 0-109.7-10.7-160.6-31.8-49.1-20.4-93.3-49.6-131.2-86.7-37.9-37.2-67.7-80.5-88.5-128.7-21.5-49.9-32.4-103-32.4-157.6h10c0 217.7 180.6 394.9 402.6 394.9s402.6-177.1 402.6-394.9h10c0 54.7-10.9 107.7-32.4 157.6-20.8 48.2-50.5 91.5-88.5 128.7-37.9 37.2-82 66.4-131.2 86.7-50.6 21.1-104.7 31.8-160.4 31.8z"
            fill="#E7F5FD"
            p-id="10645"
          ></path>
          <path
            d="M587.1 792.1H437.2c-9.4 0-17 7.7-17 17v95.6c0 6 41.2 10.9 92 10.9s92-4.9 92-10.9v-95.6c-0.1-9.4-7.7-17-17.1-17z"
            fill="#5EADEF"
            p-id="10646"
          ></path>
          <path
            d="M512.1 807.8c-31.8 0-62.6-4.3-92-12.3V838s34.3 8 90.9 8 93-8 93-8v-42.5c-29.3 8-60.1 12.3-91.9 12.3z"
            fill="#4C96EC"
            p-id="10647"
          ></path>
          <path
            d="M587.1 792.1h-13.7c9.4 0 17 7.7 17 17v95.6c0 5.8-37.5 10.5-85.1 10.9h6.9c50.8 0 92-4.9 92-10.9v-95.6c-0.1-9.4-7.7-17-17.1-17z"
            fill="#8FC6F4"
            p-id="10648"
          ></path>
          <path
            d="M598.9 796.8c-3.8 1-7.6 1.9-11.4 2.7 1.8 2.7 2.9 6 2.9 9.5v31.4c8.9-1.4 13.7-2.4 13.7-2.4v-29c0-4.8-2-9.1-5.2-12.2z"
            fill="#69A5EF"
            p-id="10649"
          ></path>
          <path
            d="M146.3 449.7a365.8 384.7 0 1 0 731.6 0 365.8 384.7 0 1 0-731.6 0Z"
            fill="#E7F5FD"
            p-id="10650"
          ></path>
          <path
            d="M311.5 198.3l48.3-7.4c15.7-2.4 28-15.5 30.2-32l9.8-75.4C306.9 115 230.1 184.6 186.2 274.8h52.4c19.1 0 36-13 41.8-32.1l6.9-22.7c3.5-11.6 12.9-20 24.2-21.7z"
            fill="#3173E7"
            p-id="10651"
          ></path>
          <path
            d="M230 620.9l-41 9.4c37.5 74.4 97.6 134.3 170.6 169.6l6.1-95.1c1.4-22.7-10.7-43.9-30.4-53.2L282 626.5c-16.4-7.6-34.5-9.6-52-5.6zM493.5 308.7L488.4 438c-0.7 17.9 6.7 35.1 20 46.3l138.4 116.8 106.8-32.5-46.4-69.2c-11.1-16.6-7.2-39.5 8.7-51l30.7-22.3c13.5-9.8 31.6-9 44.3 1.9l17.5 15.1c8 6.9 18.4 9.7 28.6 7.9l41.6-7.7c-2.5-153.8-90.8-285.6-216.7-344.8L651.1 146c-1.7 7.4-5.7 14-11.3 18.7L510.4 273.5c-10.2 8.6-16.4 21.4-16.9 35.2z"
            fill="#5EADEF"
            p-id="10652"
          ></path>
          <path
            d="M552.3 739.1c1.1-4.6 3.4-8.7 6.8-11.8l29.8-27.5c5.3-4.9 12.8-6.2 19.3-3.4l19.2 8.3c10.5 4.5 14.8 17.9 9 28.2l-8.7 15.5c-4.1 7.3-11.4 11.9-19.4 12.4l-37.2 2.1c-12.3 0.7-21.7-11.3-18.8-23.8z"
            fill="#3173E7"
            p-id="10653"
          ></path>
          <path
            d="M513 65.6c-9 0-17.9 0.3-26.8 1 189.5 14.4 339 180.7 339 383.7S675.8 819.6 486.2 834c8.8 0.7 17.8 1 26.8 1 202 0 365.8-172.2 365.8-384.7S715 65.6 513 65.6z"
            fill="#F0F8FC"
            p-id="10654"
          ></path>
          <path
            d="M837 451l41.6-7.7c-1.2-74.2-22.4-143.2-58-201.4-0.4-0.6-0.8-1.2-1.1-1.9-0.5-0.9-1.1-1.7-1.6-2.6-38.4-60.4-92.4-108.8-156.1-138.6l-6.1 26.8c101.9 68.3 169.6 188.2 169.6 324.7v0.8c3.8 0.7 7.8 0.6 11.7-0.1z"
            fill="#8FC6F4"
            p-id="10655"
          ></path>
          <path
            d="M959 445.6c-0.1-3 1-34.7-0.9-39.6-7-18.6-13-15.5-43.3-41-16.5-13.9-27.8-28-52.9-39.2 8.6 22.4 10.5 50.8 15 75 4.2 2.9 3.2 2.3 6.7 5.3 16.5 13.9 25 27.6 25.4 40.9 0.3 13.3-7.5 27.6-23.2 42.4-2.4 2.2-4.9 4.4-7.6 6.6-18.1 14.8-43.4 29-73.9 41.5-76.3 31.2-178.9 50.1-288.8 53.3-109.9 3.2-213.3-9.8-291.1-36.5-30.3-10.4-55.7-22.8-74.4-36.1-3.4-2.4-6.5-4.8-9.5-7.3-16.5-13.9-25-27.6-25.4-40.9-0.3-13.3 7.5-27.6 23.2-42.4 3.8-3.6 4.3-4.1 8.9-7.6 0.3-5.2 0.9-30.6 3.5-35.7 0.4-0.8-2.8-0.1-2.5-0.9 6.1-15.6-3.9-21.4 1.6-38.2-25.2 12.9-28.7 28-44.8 43.2-21.7 20.4-37.6 26.4-39.6 43.9-1.1 9.4-0.5 31.8-0.3 39.1 0.5 20.3 8.6 50.7 44.2 80.6 14.7 12.3 32.9 23.7 54.4 33.9 13.7 6.6 28.8 12.7 45.2 18.3 75 25.8 171.4 39.6 274.4 39.6 11.1 0 22.4-0.2 33.6-0.5 115.5-3.3 224-23.5 305.5-56.8 16.7-6.8 32-14.1 45.8-21.8 20.2-11.2 37.3-23.3 51-36.2 33.9-31.7 40.3-62.6 39.8-82.9z"
            fill="#F99403"
            p-id="10656"
          ></path>
          <path
            d="M958.7 422.9c-0.5-20.3-8.6-50.7-44.2-80.6-16.5-13.9-37.5-26.5-62.6-37.8 8.6 22.4 15.3 45.8 19.8 70.1 4.2 2.9 8 5.8 11.6 8.8 16.5 13.9 25 27.6 25.4 40.9 0.3 13.3-7.5 27.6-23.2 42.4-2.4 2.2-4.9 4.4-7.6 6.6-18.1 14.8-43.4 29-73.9 41.5-76.3 31.2-178.8 50.2-288.8 53.3-109.9 3.2-213.3-9.8-291.1-36.5-30.3-10.4-55.7-22.8-74.4-36.1-3.4-2.4-6.5-4.8-9.5-7.3-16.5-13.9-25-27.6-25.4-40.9-0.3-13.3 7.5-27.6 23.2-42.4 3.8-3.6 8-7.1 12.7-10.6 3.3-24.4 8.8-48.1 16.2-70.7-25.2 12.9-46.1 27-62.2 42.2-33.9 31.9-40.4 62.7-39.9 83 0.5 20.3 8.6 50.7 44.2 80.6 14.7 12.3 32.9 23.7 54.4 33.9 13.7 6.6 28.8 12.7 45.2 18.3 75 25.8 171.4 39.6 274.4 39.6 11.1 0 22.4-0.2 33.6-0.5 115.5-3.3 224-23.5 305.5-56.8 16.7-6.8 32-14.1 45.8-21.8 20.2-11.2 37.3-23.3 51-36.2 33.9-31.8 40.4-62.7 39.8-83z"
            fill="#FCCA4C"
            p-id="10657"
          ></path>
          <path
            d="M230.9 581.6c-16.4-5.6-31.4-11.7-45.2-18.3-21.5-10.3-39.7-21.6-54.4-33.9-35.5-29.9-43.6-60.3-44.2-80.6-0.5-20.3 6-51.2 39.9-83 10-9.4 21.7-18.3 35.2-26.8 1.5-5.1 3-10.3 4.7-15.3-25.2 12.9-46.1 27-62.2 42.2-33.9 31.9-40.4 62.7-39.9 83 0.5 20.3 8.6 50.7 44.2 80.6 14.7 12.3 32.9 23.7 54.4 33.9 13.7 6.6 28.8 12.7 45.2 18.3 75 25.8 171.4 39.6 274.4 39.6 3.7 0 7.4 0 11.1-0.1-98.8-1-190.9-14.8-263.2-39.6z"
            fill="#FAAA20"
            p-id="10658"
          ></path>
          <path
            d="M958.7 422.9c-0.5-20.3-8.6-50.7-44.2-80.6-16.5-13.9-37.5-26.5-62.6-37.8 2.4 6.3 4.7 12.6 6.8 19.1 9.7 5.9 18.4 12.2 26.1 18.7 35.5 29.9 43.6 60.3 44.2 80.6 0.5 20.3-6 51.2-39.9 83-13.7 12.8-30.7 24.9-51 36.2-13.8 7.7-29.1 14.9-45.8 21.8-81.5 33.3-190 53.5-305.5 56.8-6.2 0.2-12.4 0.3-18.6 0.4 4.9 0.1 9.7 0.1 14.6 0.1 11.1 0 22.4-0.2 33.6-0.5 115.5-3.3 224-23.5 305.5-56.8 16.7-6.8 32-14.1 45.8-21.8 20.2-11.2 37.3-23.3 51-36.2 34-31.8 40.5-62.7 40-83z"
            fill="#FEE5A6"
            p-id="10659"
          ></path>
        </svg>
      </div>
      <a-menu
        v-model:selectedKeys="selectedKeys"
        theme="dark"
        mode="inline"
        v-model:openKeys="openKeys"
      >
        <a-sub-menu key="sub1">
          <template #title>
            <span>
              <svg
                t="1660965553491"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="3424"
                width="16"
                height="16"
              >
                <path
                  d="M849.92 989.866667H174.08c-51.2 0-93.866667-42.666667-93.866667-93.866667v-768c0-51.2 42.666667-93.866667 93.866667-93.866667h677.546667c51.2 0 93.866667 42.666667 93.866666 93.866667v768c-1.706667 51.2-42.666667 93.866667-95.573333 93.866667zM174.08 85.333333C150.186667 85.333333 131.413333 104.106667 131.413333 128v768c0 23.893333 18.773333 42.666667 42.666667 42.666667h677.546667c23.893333 0 42.666667-18.773333 42.666666-42.666667v-768c0-23.893333-18.773333-42.666667-42.666666-42.666667H174.08z"
                  fill="#ffffff"
                  p-id="3425"
                ></path>
                <path
                  d="M404.48 464.213333h-112.64c-32.426667 0-59.733333-27.306667-59.733333-59.733333v-122.88c0-32.426667 27.306667-59.733333 59.733333-59.733333h112.64c32.426667 0 59.733333 27.306667 59.733333 59.733333v122.88c0 34.133333-25.6 59.733333-59.733333 59.733333zM291.84 273.066667c-5.12 0-8.533333 3.413333-8.533333 8.533333v122.88c0 5.12 3.413333 8.533333 8.533333 8.533333h112.64c5.12 0 8.533333-3.413333 8.533333-8.533333v-122.88c0-5.12-3.413333-8.533333-8.533333-8.533333h-112.64zM766.293333 295.253333h-170.666666c-13.653333 0-25.6-11.946667-25.6-25.6s11.946667-25.6 25.6-25.6h170.666666c13.653333 0 25.6 11.946667 25.6 25.6s-11.946667 25.6-25.6 25.6zM766.293333 442.026667h-170.666666c-13.653333 0-25.6-11.946667-25.6-25.6s11.946667-25.6 25.6-25.6h170.666666c13.653333 0 25.6 11.946667 25.6 25.6s-11.946667 25.6-25.6 25.6zM766.293333 648.533333H257.706667c-13.653333 0-25.6-11.946667-25.6-25.6s11.946667-25.6 25.6-25.6h506.88c13.653333 0 25.6 11.946667 25.6 25.6s-10.24 25.6-23.893334 25.6z"
                  fill="#ffffff"
                  p-id="3426"
                ></path>
                <path
                  d="M766.293333 802.133333H257.706667c-13.653333 0-25.6-11.946667-25.6-25.6s11.946667-25.6 25.6-25.6h506.88c13.653333 0 25.6 11.946667 25.6 25.6s-10.24 25.6-23.893334 25.6z"
                  fill="#ffffff"
                  p-id="3427"
                ></path>
              </svg>
              <span style="margin-left: 40px; color: white">信息管理</span>
            </span>
          </template>
          <a-menu-item key="3" @click="gotoIntro">介绍</a-menu-item>
          <a-menu-item key="4" @click="gotoMembers">成员</a-menu-item>
          <a-menu-item key="5" @click="gotoProject">项目</a-menu-item>

          <a-menu-item key="6" @click="gotoPublications">论文</a-menu-item>
          <a-menu-item key="7" @click="gotoNews">新闻</a-menu-item>
        </a-sub-menu>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0">
        <!-- <a-menu
        v-model:selectedKeys="selectedKeys"
        theme="light"
        mode="horizontal"
        :style="{ lineHeight: '64px' }"
      >
        <a-menu-item key="1">nav 1</a-menu-item>
        <a-menu-item key="2">nav 2</a-menu-item>
        <a-menu-item key="3">nav 3</a-menu-item>
      </a-menu> -->
      </a-layout-header>
      <a-layout-content style="margin: 0 16px">
        <div class="visitContainer"><router-view /></div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import { useRouter } from 'vue-router';
// import visit from './visits.vue'
import { defineComponent, ref } from 'vue';
export default defineComponent({
  name: 'HomeName',
  // components:{visit},

  setup() {
    // const route = useRoute()
    const openKeys = ref(['sub1']);
    const router = useRouter();
    let collapsed = ref(false);
    let selectedKeys = ref(['1']);
    // console.log(this)

    function gotoIntro() {
      router.push({
        name: 'introduce',
      });
    }
    function gotoMembers() {
      router.push({
        name: 'member',
      });
    }
    function gotoProject() {
      router.push({
        name: 'project',
      });
    }
    function gotoPublications() {
      router.push({
        name: 'publication',
      });
    }
    const gotoNews = () => {
      router.push({
        name: 'news',
      });
    };
    return {
      collapsed,
      selectedKeys,
      openKeys,
      gotoIntro,
      gotoMembers,
      gotoProject,
      gotoPublications,
      gotoNews,
    };
  },
  // data() {
  //   return {
  //     collapsed: ref(false),
  //     selectedKeys: ref(['1']),
  //   };
  // },
});
</script>
<style scoped>
.logo {
  height: 32px;
  margin: 16px;
  /* background: rgba(255, 255, 255, 0.3); */
}

.site-layout .site-layout-background {
  background: #fff;
}
[data-theme='dark'] .site-layout .site-layout-background {
  background: #2ee7c2;
}
.visitContainer {
  /* padding:30px; */
  background-color: white;
  height: 100%;
}
.headerlist {
  list-style: none;
}
a {
  color: white;
}
</style>
