<template>
  <div style="height:120%;padding: 40px;margin-top:20px">
    <a-textarea v-model:value="intro.CNvalue" placeholder="中文" :rows="9" style="padding:20px"/>
    <a-textarea v-model:value="intro.ENvalue" placeholder="英文" :rows="9" style="margin-top:20px;padding:20px" />
    <a-button type="primary" :size="size" style="margin-top:50px" @click="submit">提交</a-button>
  </div>
</template>

<script>
import { getCurrentInstance,onMounted,reactive } from 'vue'
import {message} from'ant-design-vue'
import qs from 'qs'

export default {
    name:'introName',
    setup(){
      const token = window.sessionStorage.getItem('token')
      const {proxy} = getCurrentInstance()
      const intro = reactive({})
      onMounted(() => {
        proxy.axios.get('/other/getIntroduction',{ headers: {  Authorization: ` ${token}`  } }).then(function(response) {
          console.log(response)
          intro.CNvalue = response.data.data[0].CNintroduction
          intro.ENvalue = response.data.data[0].ENintroduction
        })
      })
      const submit=() => {
        console.log('d')
        proxy.axios.post('/other/updateIntroduction',qs.stringify({ENintroduction:intro.ENvalue,CNintroduction:intro.CNvalue}),{ headers: {  Authorization: ` ${token}`  } }).then(
          message.success('修改成功！')
          
        )
      }

      return{
        intro,
        submit
      }
    }
}
</script>

<style>

</style>