<template>
  <h2 style="margin-top: 50px">DEEPTIMELAB后台管理系统</h2>
  <a-form
    :model="formState"
    name="basic"
    :label-col="{ span: 6 }"
    :wrapper-col="{ span: 12 }"
    autocomplete="off"
    @finish="onFinish"
    @finishFailed="onFinishFailed"
  >
    <a-form-item
      label="Username"
      name="username"
      :rules="[{ required: true, message: 'Please input your username!' }]"
    >
      <a-input v-model:value="formState.username" />
    </a-form-item>

    <a-form-item
      label="Password"
      name="password"
      :rules="[{ required: true, message: 'Please input your password!' }]"
    >
      <a-input-password v-model:value="formState.password" />
    </a-form-item>

    <a-form-item :wrapper-col="{ offset: 6, span: 12 }">
      <a-button type="primary" html-type="submit">Submit</a-button>
    </a-form-item>
  </a-form>
</template>
<script>
import { useRouter } from 'vue-router';
import { defineComponent, getCurrentInstance, reactive } from 'vue';
import qs from 'qs';
import { message } from 'ant-design-vue';

export default defineComponent({
  name: 'LoginName',
  emits: ['login'],
  setup() {
    const router = useRouter();
    const { proxy } = getCurrentInstance();
    const formState = reactive({
      username: '',
      password: '',
    });

    const onFinish = (values) => {
      console.log('Success:', values);
      proxy.axios
        .post('/api/login', qs.stringify(formState))
        .then((response) => {
          console.log(response);
          window.sessionStorage.setItem('token', response.data.token);

          if (response.data.status == 0) {
            router.push({
              path: '/home',
              replace: true,
            });
          } else {
            message.error(response.data.message);
          }
        });
    };

    const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
    };

    return {
      formState,
      onFinish,
      onFinishFailed,
    };
  },
});
</script>
