import {
  Button,
  Form,
  FormItem,
  Input,
  InputPassword,
  Layout,
  LayoutHeader,
  Menu,
  MenuItem,
  PageHeader,
  Popconfirm,
  Radio,
  RadioGroup,
  Select,
  SubMenu,
  Table,
  Textarea,
  Typography,
  Upload,
} from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
const components = [
  PageHeader,
  Typography,
  Layout,
  Menu,
  MenuItem,
  SubMenu,
  LayoutHeader,
  Button,
  Textarea,
  Form,
  FormItem,
  Input,
  InputPassword,
  RadioGroup,
  Radio,
  Table,
  Upload,
  Popconfirm,
  Select,
];
export default function registerElement(app) {
  for (const el of components) {
    app.use(el);
  }
}
