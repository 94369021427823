import { createRouter, createWebHashHistory } from 'vue-router';
//引入组件
// import visit from '../components/visits.vue';

import introduce from '../components/introduce.vue';
import member from '../components/member.vue';
import project from '../components/project.vue';
import publication from '../components/publication.vue';

import home from '../components/home.vue';
import login from '../components/login.vue';
//创建一个路由器
const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: login,
  },
  {
    path: '/editNews',
    name: 'editNews',
    component: () => import('@/components/editNews.vue'),
  },
  {
    path: '/home',

    component: home,
    children: [
      // {
      //   path: '/home',
      //   name: 'visit',
      //   component: visit,
      // },

      {
        name: 'introduce',
        path: '/home',
        component: introduce,
      },
      {
        name: 'member',
        component: member,
        path: 'member',
      },

      {
        name: 'project',
        path: 'project',
        component: project,
      },
      {
        name: 'publication',
        path: 'publication',
        component: publication,
      },
      {
        name: 'news',
        path: 'news',
        component: () => import('@/components/news.vue'),
      },
    ],
  },
];
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),

  routes, //上面的路由数组
});
router.beforeEach((to) => {
  // ...
  // 返回 false 以取消导航

  if (
    !sessionStorage.getItem('token') &&
    to.path !== '/' &&
    to.path !== '/login'
  ) {
    return { name: 'login' };
  }
  return true;
});

//导出路由对象，在main.js中引用
export default router;
