<template>
  <a-button
    class="editable-add-btn"
    style="margin-bottom: 8px; text-align: left"
    @click="handleAdd"
    >Add</a-button
  >
  <a-table :columns="columns" :data-source="dataSource.list" bordered>
    <template #bodyCell="{ column, text, record }">
      <template v-if="['title'].includes(column.dataIndex)">
        <div>
          <a-input
            v-if="editableData[record.id]"
            v-model:value="editableData[record.id][column.dataIndex]"
            style="margin: -5px 0"
          />
          <template v-else>
            {{ text }}
          </template>
        </div>
      </template>
      <template v-else-if="column.dataIndex === 'operation'">
        <div class="editable-row-operations">
          <span v-if="editableData[record.id]">
            <a-typography-link @click="save(record.id)">Save</a-typography-link>
            <a-popconfirm title="Sure to cancel?" @confirm="cancel(record.id)">
              <a>Cancel</a>
            </a-popconfirm>
          </span>
          <span v-else>
            <a @click="edit(record.id)">Edit</a>
            <a-popconfirm
              v-if="dataSource.list.length"
              title="Sure to delete?"
              @confirm="onDelete(record.id)"
            >
              <a>Delete</a>
            </a-popconfirm>
          </span>
        </div>
      </template>
    </template>
  </a-table>
</template>
<script>
import qs from 'qs';
import { cloneDeep } from 'lodash-es';
import { defineComponent, getCurrentInstance, onMounted, reactive } from 'vue';
const columns = [
  {
    title: '标题',
    dataIndex: 'title',
    width: '100%',
  },
  {
    title: '操作',
    dataIndex: 'operation',
  },
];

export default defineComponent({
  name: 'PublicationName',
  setup() {
    const token = window.sessionStorage.getItem('token');
    const dataSource = reactive([]);
    const editableData = reactive({});
    const { proxy } = getCurrentInstance();
    onMounted(() => {
      proxy.axios
        .get('/publication/getPublication', {
          headers: { Authorization: ` ${token}` },
        })
        .then(function (response) {
          dataSource.list = response.data.data;
        });
    });
    const edit = (id) => {
      editableData[id] = cloneDeep(
        dataSource.list.filter((item) => id === item.id)[0]
      );
    };

    const save = (id) => {
      proxy.axios
        .post(
          '/publication/updatePublication',
          qs.stringify(editableData[id]),
          { headers: { Authorization: ` ${token}` } }
        )
        .then(function (response) {
          console.log(response);
        });
      Object.assign(
        dataSource.list.filter((item) => id === item.id)[0],
        editableData[id]
      );
      delete editableData[id];
    };

    const cancel = (key) => {
      delete editableData[key];
    };
    const onDelete = (id) => {
      proxy.axios.get('/publication/deletePublication/' + id, {
        headers: { Authorization: ` ${token}` },
      });
      dataSource.list = dataSource.list.filter((item) => item.id !== id);
    };
    const handleAdd = () => {
      const newData = {
        title: '标题',
      };

      proxy.axios
        .post('/publication/addPublication', qs.stringify(newData), {
          headers: { Authorization: ` ${token}` },
        })
        .then(function (response) {
          console.log(response);

          newData.id = response.data.id;
          dataSource.list.unshift(newData);
        });
    };
    return {
      dataSource,
      columns,
      editingKey: '',
      editableData,
      edit,
      save,
      cancel,
      onDelete,
      handleAdd,
    };
  },
});
</script>
<style scoped>
.editable-row-operations a {
  margin-right: 8px;
}
</style>
