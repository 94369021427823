import { createApp } from 'vue';
import App from './App.vue';
// import Antd from 'ant-design-vue';

//引入路由器
import router from './router';

import axios from 'axios';
import { registerApp } from './global';

axios.defaults.baseURL = 'https://labapi.machao.group';

const app = createApp(App).use(router);
// app.config.globalProperties.msg = 'hello'
registerApp(app);
app.config.globalProperties.axios = axios;
app.mount('#app');
