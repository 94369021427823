<template>
  <div>
    <div>
      <a-radio-group v-model:value="typevalue" @change="changeType">
        <a-radio value="teacher">Teacher</a-radio>
        <a-radio value="phd">PhD</a-radio>
        <a-radio value="master">Master</a-radio>
        <a-radio value="undergrauate">Undergraduate</a-radio>
        <a-radio value="graduate">Graduate</a-radio>
      </a-radio-group>
    </div>
    <div>
      <a-button
        class="editable-add-btn"
        style="margin-bottom: 8px; text-align: left"
        @click="handleAdd"
        >Add</a-button
      >
      <a-table
        v-if="typevalue === 'teacher'"
        :columns="Teacherscolumns"
        :data-source="dataSource.list"
        bordered
      >
        <template #bodyCell="{ column, text, record, index }">
          <template v-if="['picture'].includes(column.dataIndex)">
            <div>
              <span v-if="editableData[record.id]">
                <img :src="text" style="height: 100px; width: 100px" />
              </span>
              <span v-else>
                <a-upload
                  v-model:file-list="fileList"
                  :name="record.CNname"
                  list-type="picture-card"
                  class="avatar-uploader"
                  method="post"
                  :customRequest="
                    (info) => customImageRequest(info, record.id, column)
                  "
                  :showUploadList="false"
                  :data="{ id: record.id }"
                  :before-upload="beforeUpload"
                  @change="(file) => handleChange(file, record.id, index)"
                >
                  <img
                    v-if="record.picture"
                    :src="record.picture"
                    class="picture"
                    style="width: 100px; height: 100px"
                    alt="avatar"
                  />
                  <div v-else>
                    <loading-outlined v-if="loading"></loading-outlined>
                    <plus-outlined v-else></plus-outlined>
                    <div class="ant-upload-text">Upload</div>
                  </div>
                </a-upload>
              </span>
            </div>
          </template>
          <template v-else-if="column.dataIndex === 'operation'">
            <div class="editable-row-operations">
              <span v-if="editableData[record.id]">
                <a-typography-link @click="save(record.id)"
                  >Save</a-typography-link
                >
                <a-popconfirm
                  title="Sure to cancel?"
                  @confirm="cancel(record.id)"
                >
                  <a>Cancel</a>
                </a-popconfirm>
              </span>
              <span v-else>
                <a @click="edit(record.id)">Edit</a>
                <a-popconfirm
                  v-if="dataSource.list.length"
                  title="Sure to delete?"
                  @confirm="onDelete(record.id)"
                >
                  <a>Delete</a>
                </a-popconfirm>
              </span>
            </div>
          </template>
          <template v-else>
            <a-input
              v-if="editableData[record.id]"
              v-model:value="editableData[record.id][column.dataIndex]"
              style="margin: -5px 0"
            />
            <span v-else>
              {{ text }}
            </span>
          </template>
        </template>
      </a-table>
      <a-table
        v-else
        :columns="Studentscolumns"
        :data-source="dataSource.list"
        bordered
      >
        <template #bodyCell="{ column, text, record, index }">
          <template v-if="['picture'].includes(column.dataIndex)">
            <div>
              <span v-if="editableData[record.id]">
                <img :src="text" style="height: 100px; width: 100px" />
              </span>
              <span v-else>
                <a-upload
                  v-model:file-list="fileList"
                  :name="record.CNname"
                  list-type="picture-card"
                  class="avatar-uploader"
                  method="post"
                  :customRequest="
                    (info) => customImageRequest(info, record.id, column)
                  "
                  action="https:/www.machao.group/uploadImg"
                  :showUploadList="false"
                  :data="{ id: record.id }"
                  :before-upload="beforeUpload"
                  @change="(file) => handleChange(file, record.id, index)"
                >
                  <img
                    v-if="record.picture"
                    :src="record.picture"
                    class="picture"
                    style="width: 100px; height: 100px"
                    alt="avatar"
                  />

                  <div v-else>
                    <loading-outlined v-if="loading"></loading-outlined>
                    <plus-outlined v-else></plus-outlined>
                    <div class="ant-upload-text">Upload</div>
                  </div>
                </a-upload>
              </span>
            </div>
          </template>
          <template v-else-if="['type'].includes(column.dataIndex)">
            <div v-if="editableData[record.id]">
              <a-select
                ref="select"
                v-model:value="editableData[record.id][column.dataIndex]"
                style="width: 120px"
                @focus="focus"
                @change="handleTypeChange"
              >
                <a-select-option value="master">Master</a-select-option>
                <a-select-option value="phd">Phd</a-select-option>
                <a-select-option value="undergraduate"
                  >Undergraduate</a-select-option
                >
                <a-select-option value="graduate">Graduate</a-select-option>
              </a-select>
            </div>
          </template>
          <template v-else-if="column.dataIndex === 'operation'">
            <div class="editable-row-operations">
              <span v-if="editableData[record.id]">
                <a-typography-link @click="save(record.id)"
                  >Save</a-typography-link
                >
                <a-popconfirm
                  title="Sure to cancel?"
                  @confirm="cancel(record.id)"
                >
                  <a>Cancel</a>
                </a-popconfirm>
              </span>
              <span v-else>
                <a @click="edit(record.id)">Edit</a>
                <a-popconfirm
                  v-if="dataSource.list.length"
                  title="Sure to delete?"
                  @confirm="onDelete(record.id)"
                >
                  <a>Delete</a>
                </a-popconfirm>
              </span>
            </div>
          </template>

          <template v-else>
            <a-input
              v-if="editableData[record.id]"
              v-model:value="editableData[record.id][column.dataIndex]"
              style="margin: -5px 0"
            />
            <span v-else>
              {{ text }}
            </span>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue';
import qs from 'qs';
import { cloneDeep } from 'lodash-es';
import { message } from 'ant-design-vue';
import {
  defineComponent,
  reactive,
  ref,
  getCurrentInstance,
  onMounted,
} from 'vue';
import { useRouter } from 'vue-router';
import { compressImg } from '@/utils/processImage';
const Studentscolumns = [
  {
    title: '姓名(CN)',
    dataIndex: 'CNname',
    width: '10%',
  },
  {
    title: '姓名(EN)',
    dataIndex: 'ENname',
    width: '15%',
  },
  {
    title: '专业(CN)',
    dataIndex: 'CNmajor',
    width: '12%',
  },
  {
    title: '专业(EN)',
    dataIndex: 'ENmajor',
    width: '12%',
  },
  {
    title: '年级',
    dataIndex: 'CNgrade',
    width: '10%',
  },
  {
    title: 'grade',
    dataIndex: 'ENgrade',
    width: '8%',
  },
  {
    title: '照片',
    dataIndex: 'picture',
    width: '15%',
  },
  {
    title: '类别',
    dataIndex: 'type',
  },
  {
    title: 'operation',
    dataIndex: 'operation',
  },
];
const Teacherscolumns = [
  {
    title: '姓名(CN)',
    dataIndex: 'CNname',
    width: '10%',
  },
  {
    title: '姓名(EN)',
    dataIndex: 'ENname',
    width: '15%',
  },
  {
    title: '职称(CN)',
    dataIndex: 'CNmajor',
    width: '12%',
  },
  {
    title: '职称(EN)',
    dataIndex: 'ENmajor',
    width: '10%',
  },
  {
    title: '研究方向',
    dataIndex: 'CNinterest',
    width: '10%',
  },
  {
    title: 'interests',
    dataIndex: 'ENinterest',
    width: '8%',
  },
  {
    title: '照片',
    dataIndex: 'picture',
    width: '15%',
  },
  {
    title: '顺序',
    dataIndex: 'showorder',
    width: '5%',
  },
  {
    title: '链接',
    dataIndex: 'path',
    width: '15%',
  },
  {
    title: 'operation',
    dataIndex: 'operation',
  },
];
// function getBase64(img, callback) {
//   const reader = new FileReader();
//   reader.addEventListener('load', () => callback(reader.result));
//   reader.readAsDataURL(img);
// }

export default defineComponent({
  name: 'MemberName',
  components: {
    LoadingOutlined,
    PlusOutlined,
  },
  setup() {
    const typevalue = ref('teacher');
    const token = window.sessionStorage.getItem('token');
    const { proxy } = getCurrentInstance();
    const dataSource = reactive([]);
    const TeacherList = ref({});
    const PHDStudentsList = ref({});
    const MasterStudentsList = ref({});
    const UndergraduateStudentsList = ref({});
    const GraduateStudentsList = ref({});
    const isChangeType = ref(false);
    function onloadData() {
      proxy.axios
        .get('/deeptime/getMembers', {
          headers: { Authorization: ` ${token}` },
        })
        .then(function (response) {
          const list = response.data.data;

          TeacherList.value = list.filter((item) => item.type == 'teacher');

          PHDStudentsList.value = list.filter((item) => item.type == 'phd');
          MasterStudentsList.value = list.filter(
            (item) => item.type == 'master'
          );

          UndergraduateStudentsList.value = list.filter(
            (item) => item.type == 'undergraduate'
          );
          GraduateStudentsList.value = list.filter(
            (item) => item.type == 'graduate'
          );
          switch (typevalue.value) {
            case 'teacher':
              dataSource.list = TeacherList.value;
              break;
            case 'phd':
              dataSource.list = PHDStudentsList.value;
              break;
            case 'master':
              dataSource.list = MasterStudentsList.value;
              break;
            case 'undergrauate':
              dataSource.list = UndergraduateStudentsList.value;
              break;
            case 'graduate':
              dataSource.list = GraduateStudentsList.value;
          }
        });
    }
    onMounted(() => {
      onloadData();
    });

    const loading = ref(false);
    // const imageUrl = ref('');
    const router = useRouter();

    const editableData = reactive({});
    // const count = computed(() => dataSource.list.length + 1);
    const fileList = ref([]);

    const edit = (id) => {
      editableData[id] = cloneDeep(
        dataSource.list.filter((item) => id === item.id)[0]
      );
      console.log(editableData[id]);
    };

    const save = (id) => {
      proxy.axios
        .post('/member/updateMember/' + id, qs.stringify(editableData[id]), {
          headers: { Authorization: ` ${token}` },
        })
        .then(function (response) {
          console.log(response);
          if (isChangeType.value) {
            onloadData();
            isChangeType.value = false;
          }
        });
      console.log(editableData[id]);
      Object.assign(
        dataSource.list.filter((item) => id === item.id)[0],
        editableData[id]
      );
      delete editableData[id];
    };

    const cancel = (key) => {
      delete editableData[key];
    };
    const handleAdd = () => {
      const newData = {
        ENname: 'English Name',
        CNmajor: '地质学',
        CNname: '中文名',
        ENmajor: 'Geology',
        picture: `pic`,
        CNgrade: '年级',
        ENgrade: 'grade',
      };

      let mydata = {
        ENname: newData.ENname,
        CNname: newData.CNname,
        CNmajor: newData.CNmajor,
        ENmajor: newData.ENmajor,
        ENgrade: newData.ENgrade,
        CNgrade: newData.CNgrade,
        picture: newData.picture,
        type: typevalue.value,
      };

      proxy.axios
        .post('/member/addMember', qs.stringify(mydata), {
          headers: { Authorization: ` ${token}` },
        })
        .then(function (response) {
          console.log(response);
          if (response.data.code === 200) {
            newData.id = response.data.id;
            dataSource.list.unshift(newData);
          }
        });
    };
    const onDelete = (id) => {
      console.log(id);
      proxy.axios
        .get('/member/deleteMember/' + id, {
          headers: { Authorization: ` ${token}` },
        })
        .then(function (response) {
          console.log(response);
          dataSource.list = dataSource.list.filter((item) => item.id !== id);
        });
    };
    const changeType = () => {
      switch (typevalue.value) {
        case 'teacher':
          dataSource.list = TeacherList.value;
          break;
        case 'phd':
          dataSource.list = PHDStudentsList.value;
          break;
        case 'master':
          dataSource.list = MasterStudentsList.value;
          break;
        case 'undergrauate':
          dataSource.list = UndergraduateStudentsList.value;
          break;
        case 'graduate':
          dataSource.list = GraduateStudentsList.value;
          break;
      }
      // proxy.axios
      //   .get('/member/getMembers/' + typevalue.value, {
      //     headers: { Authorization: ` ${token}` },
      //   })
      //   .then(function (response) {
      //     dataSource.list = response.data.data;
      //     console.log(dataSource.list);
      //   });
    };
    const customImageRequest = (info, id) => {
      const { file } = info;
      // blob方式预览图片
      // this.imageUrl = window.URL.createObjectURL(file);
      // 组装数据
      const formData = new FormData();
      const newfile = compressImg(file);
      newfile.then((res) => {
        console.log(res, file);
        res.uid = file.uid;
        formData.append('files', res);
        formData.append('id', id);
        console.log(res);
        // formData.append('files', file);
        proxy.axios
          .post('/uploadImg', formData, {
            headers: { Authorization: ` ${token}` },
          })
          .then((res) => {
            console.log(res);
            loading.value = false;
            message.info(res.data.message);
            // getBase64(info.file, (base64Url) => {
            //   console.log(index);
            //   dataSource.list[index].picture = base64Url;
            //   // imageUrl.value = base64Url;

            //   loading.value = false;
            // });
            // setTimeout(() => {
            //   window.location.reload();
            // }, 2000);
          });
      });

      // 发送请求
    };
    const handleChange = (info, id, index) => {
      console.log(index);
      if (info.file.status === 'uploading') {
        loading.value = true;

        return;
      }

      if (info.file.status === 'done') {
        // Get this url from response in real world.
        loading.value = false;

        // getBase64(info.file.originFileObj, (base64Url) => {
        //   dataSource.list[index].picture = base64Url;
        //   // imageUrl.value = base64Url;
        //   loading.value = false;
        // });
      }

      if (info.file.status === 'error') {
        loading.value = false;

        message.error('upload error');
      }
    };

    const beforeUpload = (file) => {
      const isJpgOrPng =
        file.type === 'image/jpeg' || file.type === 'image/png';

      if (!isJpgOrPng) {
        message.error('You can only upload JPG file!');
      }

      const isLt2M = file.size / 1024 / 1024 < 1;

      if (!isLt2M) {
        // const newfile = compressImg(file);
        // newfile.then((res) => {
        //   console.log(res);
        // });
        // fileList.value.push(newfile);
      }
      // };

      return isJpgOrPng;
    };
    const handleTypeChange = () => {
      isChangeType.value = true;
    };
    return {
      dataSource,
      Studentscolumns,
      Teacherscolumns,
      editingKey: '',
      editableData,
      edit,
      save,
      cancel,
      router,
      handleAdd,
      onDelete,
      fileList,
      handleChange,
      beforeUpload,
      loading,
      typevalue,
      token,
      changeType,
      customImageRequest,
      compressImg,
      TeacherList,
      handleTypeChange,
    };
  },
});
</script>

<style scoped>
.editable-row-operations a {
  margin-right: 8px;
}

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
