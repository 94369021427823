<template>
  <a-button
    class="editable-add-btn"
    style="margin-bottom: 8px; text-align: left"
    @click="handleAdd"
    >Add</a-button
  >
  <a-table
    :columns="columns"
    :data-source="dataSource.list"
    bordered
    :scroll="{ x: 1500 }"
  >
    <template #bodyCell="{ column, text, record }">
      <template
        v-if="
          ['CNname', 'ENname', 'time', 'ENdetail', 'CNdetail'].includes(
            column.dataIndex
          )
        "
      >
        <div>
          <a-textarea
            :rows="7"
            v-if="editableData[record.id]"
            v-model:value="editableData[record.id][column.dataIndex]"
            style="margin: -5px 0"
          />
          <template v-else>
            {{ text }}
          </template>
        </div>
      </template>
      <template v-else-if="column.dataIndex === 'operation'">
        <div class="editable-row-operations">
          <span v-if="editableData[record.id]">
            <a-typography-link @click="save(record.id)">Save</a-typography-link>
            <a-popconfirm title="Sure to cancel?" @confirm="cancel(record.id)">
              <a>Cancel</a>
            </a-popconfirm>
          </span>
          <span v-else>
            <a @click="edit(record.id)">Edit</a>
            <a-popconfirm
              v-if="dataSource.list.length"
              title="Sure to delete?"
              @confirm="onDelete(record.id)"
            >
              <a>Delete</a>
            </a-popconfirm>
          </span>
        </div>
      </template>
    </template>
  </a-table>
</template>
<script>
import { cloneDeep } from 'lodash-es';
import qs from 'qs';
import { defineComponent, getCurrentInstance, onMounted, reactive } from 'vue';
const columns = [
  {
    title: '项目名',
    dataIndex: 'CNname',
    width: '20%',
  },
  {
    title: 'ProjectName',
    dataIndex: 'ENname',
    width: '15%',
  },
  {
    title: '时间',
    dataIndex: 'time',
    width: '10%',
  },
  {
    title: '详述',
    dataIndex: 'CNdetail',
    width: '25%',
  },
  {
    title: 'detail',
    dataIndex: 'ENdetail',
    width: '30%',
  },
  {
    title: 'operation',
    dataIndex: 'operation',
  },
];

export default defineComponent({
  name: 'ProjectName',
  setup() {
    const token = window.sessionStorage.getItem('token');
    const { proxy } = getCurrentInstance();
    const dataSource = reactive([]);
    onMounted(() => {
      proxy.axios
        .get('/project/getProject', { headers: { Authorization: ` ${token}` } })
        .then(function (response) {
          dataSource.list = response.data.data;
          console.log(response);
        });
    });

    const editableData = reactive({});
    const handleAdd = () => {
      const newData = {
        ENname: 'Project Name',
        CNdetail: '详述',
        CNname: '项目名',
        time: '时间',
        ENdetail: `Detail`,
      };
      let mydata = {
        ENname: newData.ENname,
        CNname: newData.CNname,
        time: newData.time,
        ENdetail: newData.ENdetail,
        CNdetail: newData.CNdetail,
      };

      proxy.axios
        .post('/project/addProject', qs.stringify(mydata), {
          headers: { Authorization: ` ${token}` },
        })
        .then(function (response) {
          console.log(response);
          if (response.data.code === 200) {
            newData.id = response.data.id;
            dataSource.list.unshift(newData);
          }
        });
    };
    const edit = (id) => {
      // console.log(dataSource.list)
      // console.log(dataSource.list.filter(item => id === item.id)[0])
      editableData[id] = cloneDeep(
        dataSource.list.filter((item) => id === item.id)[0]
      );
    };

    const save = (id) => {
      console.log(editableData[id]);
      proxy.axios
        .post('project/updateProject', qs.stringify(editableData[id]), {
          headers: { Authorization: ` ${token}` },
        })
        .then(function (response) {
          console.log(response);
        });
      Object.assign(
        dataSource.list.filter((item) => id === item.id)[0],
        editableData[id]
      );
      delete editableData[id];
    };

    const cancel = (key) => {
      delete editableData[key];
    };
    const onDelete = (id) => {
      proxy.axios
        .get('/project/deleteProject/' + id, {
          headers: { Authorization: ` ${token}` },
        })
        .then(function (response) {
          console.log(response);
          dataSource.list = dataSource.list.filter((item) => item.id !== id);
        });
    };
    return {
      dataSource,
      columns,
      editingKey: '',
      editableData,
      edit,
      save,
      cancel,
      onDelete,
      handleAdd,
    };
  },
});
</script>
<style scoped>
.editable-row-operations a {
  margin-right: 8px;
}
</style>
